<template>
  <div class="IntellectualProperty">
    <div class="name">
        知识产权
    </div>
    <div class="collapse">
      <a-row class="collapse_ul">
        <a-col :md="24" :lg="12" :xl="8" v-for="(item,index) in list" :key="index">
          <el-image
            class="collapse_img"
            :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Intellectual0'+(index + 1)+'.png'"
            :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Intellectual0'+(index + 1)+'.png']"
          >
          </el-image>
          <p :class="{'p2':item.pclass}" v-html="item.desc"></p>
        </a-col>
      </a-row>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'IntellectualProperty',
 components:{},
  data () {
    return {
      list: [
        {
          desc: '国家发明专利<br>基于区块链的数字作品版权保护方法',
          pclass: false
        },
        {
          desc: '国家发明专利副本<br>基于区块链的数字作品版权保护方法',
          pclass: false
        },
        {
          desc: '中国知链—基于区块链技术的<br>公共知识服务平台',
          pclass: false
        },
        {
          desc: '中国知链—基于区块链技术的学术论文<br>知识产权保护及认证体系平台',
          pclass: false
        },
        {
          desc: '中国阅读指数平台',
          pclass: true
        },
        {
          desc: '中国知链-在线教育培训平台',
          pclass: true
        },
        {
          desc: '中国教育信息化数据库系统',
          pclass: true
        },
        {
          desc: '论文认证平台',
          pclass: true
        },
        {
          desc: '教育信息化论文首发平台',
          pclass: true
        },
      ]
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.collapse{
  padding: 20px 0;
  p{
  color: rgba(0, 18, 22, 0.65);
  padding: 10px 0;
  line-height:1.769230769230769;
  }
  .collapse_ul{
    .collapse_img{
      display: block;
      margin: 0 auto;
      width: 248px;
      height: 352px;
    }
    p{
      width: 248px;
      height: 48px;
      margin: 10px auto 40px auto;
      padding: 4px 0;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background: #007DFF;
    }
    .p2{
      line-height: 40px;
    }
  }
}
.IntellectualProperty {
    padding: 0 32px 0;
}
.name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,18,22,.85);
    line-height: 36px;
    padding: 32px 0 32px 0;
    border-bottom: 1px solid #ebeef5;
}
</style>
